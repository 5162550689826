import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Components
import SectionTitle from "../common/section-title";

import img1 from "../../assets/images/features/greenivity_ai.png";

class GetStarted extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="greenivity-section-3" id="carbon">
          <Container>
            <Row>
              <Col md="12" className="text-white critarea-desc mt-3 mb-3">
              <SectionTitle title2="What do you need know?" />
                  <ul class="experiences">
                  <li class="green">
                      <div class="where">Who is greenivity?</div>
                      <p class="description">
                      Greenivity is a B2B focussed climate tech startup supporting companies in their emission reporting requirements through their unique client-friendly platform applying AI and blockchain technologies.
                      <br></br>
                      Reporting data points obtained via ERP or IoT devices will be used to evaluate current data and trends, to create forward looking scenarios to optimise net zero positioning by either buying or selling carbon credits.  
                      <br></br>
                      Creating data pipes  between the Greenivity platform and global carbon credit exchange platforms with automated order execution at pre-set tolerance levels to manage both a greener world and positive accounting impact. 
                      </p>
                    </li>
                    <li class="green">
                      <div class="where">Why is Carbon Management so important?</div>
                      <p class="description">
                      You cannot track what you cannot measure and therefore manage. Climate effects are radically demonstrating the impact of mankind on Mother Nature. <br></br>
                      All countires, some more some less, are taken steps to prevent further detoriation and climate regulation is set up to manage this with increasing mandatory requirements in terms of reporting and compliance. <br></br>
                      To reach net-zero emissions before 2050, all organizations need to account for and share their GHG emissions in order to create carbon reduction schemes. <br></br>
                      Non-compliance may potentially lead to fines but also reputational risk. <br></br>
                      Greenivity helps you in getting a clear insight to establish and track your carbon baseline through the usage of an AI powered SaaS platform.
                      </p>
                    </li>
                    <li class="green">
                      <div class="where">Your Trusted Sustainable Partner</div>
                      <p class="description">At Greenivity, we reach out towards a more sustainable future. Specialized in emission measurement and reporting, <br></br>
                      we are your strategic ally in consulting to reduce greenhouse gases across all facets of the industry. <br></br>
                      Moreover, our expertise spans the entire spectrum of the sector, including comprehensive solutions for the Voluntary Carbon Market (VCM).
                      </p>
                    </li>
                  </ul>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default GetStarted;
