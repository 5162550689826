import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import BackgroundSlider from 'react-background-slider';

//Import Text-Rotator
import ReactTextRotator from 'react-text-rotator';

//Importing Modal
import ModalSection from '../components/common/ModalSection';

//Import Images
import image1 from "../assets/images/forest.jpg";
import image2 from "../assets/images/net_zero.jpeg";
import image3 from "../assets/images/bg-3.jpeg";


const RotateComponent = () => (

    <ReactTextRotator
      content={content}
      time={6000}
      startDelay={500}
    />
);

        const content = [
            {
                text: 'AI powered climate tech SAAS platform using blockchain solutions',
                animation: 'fade',
            },
            {
                text: 'Greenivity supports your company to achieve Net Zero Ø',
                animation: 'fade',
            },
            {
                text: 'Reversing climate change through decarbonizing process',
                animation: 'fade',
            },
        ];

class Section extends Component {
    constructor() {
        super();
        this.state = {
            isOpen: false
        }
        this.callModal.bind(this)
    }

    callModal = () => {
        this.refs.child.openModal();
    }
    
    render() {
        return (
            <React.Fragment>

        <section className="back-slide" id="home">
        <BackgroundSlider
            images={[image1, image2, image3]}
            duration={5}
            transition={2}
        />
            <div className="bg-overlay"></div>
            <div className="home-center">
                <div className="home-desc-center">
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg="10" className="text-center">
                                <h6 className="home-title text-white">
                                    <div className="text-rotate">
                                        <RotateComponent/>
                                    </div>
                                </h6>
                                <p className="pt-4 home-sub-title text-white mx-auto"></p>
                                <div className="watch-video pt-4">
                                    {/* <Link onClick={this.callModal} to="#" className="video-play-icon text-white"><i className="mdi mdi-play play-icon-circle play play-iconbar"></i> <span>Watch The Video!</span></Link> */}
                                     <a className='btn btn-primary btn-custom' href="#contact">Schedule a demo</a>
                                </div>
                            </Col>
                        </Row>
                        {/* Render ModalSection Component for Modal */}
                                <ModalSection ref="child" channel='vimeo' videoId='99025203' />
                    </Container>
                </div>
            </div>
        </section>
            </React.Fragment>
        );
    }
}

export default Section;