import React, { Component } from 'react';

class FooterLinks extends Component {
    getYear() {
        return new Date().getFullYear();
    }
    render() {
        return (
            <React.Fragment>
        <div className="footer-alt">
            <p className="copy-rights"> {this.getYear()} © Greenivity.</p>
        </div>
            </React.Fragment>
        );
    }
}

export default FooterLinks;
