import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Components
import SectionTitle from "../../components/common/section-title";

//Import Images
import img1 from "../../assets/images/features/greenivity_ai.png";
import img2 from "../../assets/images/features/greenivity_blockchain.png";
import img3 from "../../assets/images/features/mockup2.png";
import img4 from "../../assets/images/features/mockup1.png";
import FeatureBox from "../common/feature-box";

class Features extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="section" id="features">
          <Container>
            <SectionTitle title1="Our" title2="AI blockchain powered MRV Solution" />
            <Row className="mt-4 pt-4 vertical-content features-vertical">
              <Col lg="6" className="mt-2">
                <div className="features-image-left">
                  <img
                    src={img1}
                    alt=""
                    className="img-fluid mx-auto d-block"
                  />
                </div>
              </Col>
              <Col lg="6" className="mt-2 text-center">
                <FeatureBox
                  class=""
                  title="AI"
                  desc={
                    <React.Fragment>
                      <p>
                        Leverage the power of the Greenivity platform applying your corporate emission data: Unveil future corporate trends with predictive modelling, pinpoint potential discrepancies within your company through anomaly detection based upon a combination of internal and industry set tolerance levels, verifying GHG Policy compliance and streamline reporting with automated report generation
                      </p>
                      <br></br>
                      <ul className="features-horizontal-list">
                          <li>Predictive modelling</li>
                          <li>Anomaly detection</li>
                          <li>Risk assessment and targeted verification:AI can analyse data to identify projects or data points with a higher risk of error or manipulation. This allows you to focus verification efforts on areas that need it most, optimising resource allocation.</li>
                          <li>GHG Industry Policy benchmarking vs real actual corporate GHG output. </li>
                          <li>Automated report generation</li>
                      </ul>
                    </React.Fragment>
                  }
                  link="#"
                />
              </Col>
            </Row>
            <Row className="mt-5 vertical-content features-vertical">
              <Col lg="6" className="mt-2">
                <FeatureBox
                  class="text-center"
                  title="Blockchain"
                  desc={
                    <React.Fragment>
                      <p>
                      Harness the power of blockchain for transparent emissions management: Project verification ensures legitimacy, token minting quantifies impact, while transparency and traceability empower trust, all secured by immutability. This fosters a responsible and accountable future for carbon management.
                      </p>
                      <br></br>
                      <ul className="features-horizontal-list">
                          <li>Project Verification</li>
                          <li>Token minting</li>
                          <li>Transparency</li>
                          <li>Traceability</li>
                          <li>Immutability</li>
                      </ul>
                    </React.Fragment>
                  }
                  link="#"
                />
              </Col>
              <Col lg="6" className="mt-2">
                <div className="features-image-right">
                  <img
                    src={img2}
                    alt=""
                    className="img-fluid mx-auto d-block"
                  />
                </div>
              </Col>
            </Row>
            {/* <Row className="mt-4 pt-4 vertical-content">
              <Col lg="6" className="mt-2">
                <div className="features-image-left">
                  <img
                    src={img3}
                    alt=""
                    className="img-fluid mx-auto d-block"
                  />
                </div>
              </Col>
              <Col lg="6" className="mt-2 text-center">
                <FeatureBox
                  class=""
                  title="Reporting"
                  desc="Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                  link="#"
                />
              </Col>
            </Row> */}
            {/* <Row className="mt-5 vertical-content">
              <Col lg="6" className="mt-2">
                <FeatureBox
                  class="text-center"
                  title="Optimization"
                  desc="Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                  link="#"
                />
              </Col>
              <Col lg="6" className="mt-2">
                <div className="features-image-right">
                  <img
                    src={img4}
                    alt=""
                    className="img-fluid mx-auto d-block"
                  />
                </div>
              </Col>
            </Row> */}
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Features;
