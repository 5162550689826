import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Components
import SectionTitle from "../common/section-title";


class Clients extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="section greenivity-section-choose-us why-us-sec" id="why-us">
          <Container>
            <SectionTitle title1="Why" title2="Choose us?" />
            <Row className="mt-4 pt-4 justify-content-center">
              <Col lg="3">
                <div class="service-card">
                  <div class="icon-wrapper">
                    <i class="fa-solid fa-chart-line"></i>
                  </div>
                  <h3>Reduce Cost</h3>
                  <p>
                  We assist in lowering costs associated with GHG emissions.
                  </p>
                </div>
              </Col>
              <Col lg="3">
                <div class="service-card">
                  <div class="icon-wrapper">
                    <i class="fa-solid fa-chart-line"></i>
                  </div>
                  <h3>Enhancing Precision</h3>
                  <p>
                  Our focus on enhancing accuracy ensures that your emissions data is reliable and error-free. We employ advanced methodologies to minimize any room for measurement discrepancies, providing you with the most precise insights for better decision-making.
                  </p>
                </div>
              </Col>
              <Col lg="3">
                <div class="service-card">
                  <div class="icon-wrapper">
                    <i class="fa-solid fa-chart-line"></i>
                  </div>
                  <h3>Cutting-Edge Innovation</h3>
                  <p>
                  Leveraging AI, BI, and Blockchain technologies, we offer state-of-the-art solutions that push the boundaries of emissions management.
                  </p>
                </div>
              </Col>
              <Col lg="3">
                <div class="service-card">
                  <div class="icon-wrapper">
                    <i class="fa-solid fa-chart-line"></i>
                  </div>
                  <h3>Customization</h3>
                  <p>
                  Our solution is designed to meet the generic needs of each industry, tailoring the specific features to each client's individual requirements.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Clients;
