import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

//Import Footer link
import FooterLinks from "./footer-links";

class Footer extends Component {
    state = {
        footerItems : [
            { title : "about Linexon" ,
                links : [
                    { linkTitle : "About Us", link : "#" },
                    { linkTitle : "Press", link : "#" },
                    { linkTitle : "Job Opening", link : "#" },
                    { linkTitle : "Terms", link : "#" },
                    { linkTitle : "Privacy", link : "#" },
                ]
            },
            { title : "resourses" ,
                links : [
                    { linkTitle : "Blog", link : "#" },
                    { linkTitle : "Help Center", link : "#" },
                    { linkTitle : "Academy", link : "#" },
                    { linkTitle : "Video Terms", link : "#" },
                    { linkTitle : "Monitoring Grader", link : "#" },
                    { linkTitle : "Linexon API", link : "#" },
                ]
            },
            { title : "more info" ,
                links : [
                    { linkTitle : "Pricing", link : "#" },
                    { linkTitle : "For Marketing", link : "#" },
                    { linkTitle : "For CEOs", link : "#" },
                    { linkTitle : "For Agencies", link : "#" },
                    { linkTitle : "Our Apps", link : "#" },
                ]
            },
        ]
    }
    render() {
        return (
            <React.Fragment>
        <section className="section bg-light"> 
            <Container>
                <Row>
                    <Col lg="12">
                        <div className='text-center'>
                            <p className="text-uppercase footer-title">Contact Us</p>
                            <div className="contact-us">
                                <p>info@greenivity.io</p>
                            </div>
                            <ul className="footer-icons list-inline mt-4"> 
                                <li className="list-inline-item"><Link to="#" className=""><i className="mdi mdi-linkedin"></i></Link></li> 
                                <li className="list-inline-item"><Link to="#" className=""><i className="mdi mdi-twitter"></i></Link></li> 
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
                {/* Render footer links */}
                <FooterLinks/>
            </React.Fragment>
        );
    }
}

export default Footer;