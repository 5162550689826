import React, { Component, useRef, useEffect, useState } from "react";
import { Container, Row, Col, FormGroup, Label } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Animated } from "react-animated-css";
import emailjs from 'emailjs-com';

//Import Section Title
import SectionTitle from "../common/section-title";

const InitialFieldValues = {
  firstname: "",
  email: "",
  subject: "",
  comments: "",
}

const GetInTouch = () => {

  const [ formFields, setFromFields ] = useState(InitialFieldValues);
  const [ msgSendSuccess, setMsgSendSuccess ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState(false);
  const [ formSubmitted, setFormSubmitted ] = useState(false);

  const form = useRef();

  const handleSubmit = (event, form) => {
    event.preventDefault();

    const serviceID = 'service_vi0azin';
    const templateID = 'template_rpz268a';

    setLoading(true);
    setMsgSendSuccess(false);

    emailjs.sendForm(serviceID, templateID, form)
      .then((result) => {
        setFormSubmitted(true);
        setMsgSendSuccess(true);
        setLoading(false);
      }, (err) => {
        setFormSubmitted(true);
        setLoading(false);
        setError(true);
      });
  };

  const onInputChangeHandler = (event) => {
    const value = event.target.value;
    const name = event.target.name;

    setFromFields((prev, props) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    emailjs.init('Mi4PCZdVy5Mm0TH3J');

    document.getElementById('contact-form')
    .addEventListener('submit', function(event) {
      handleSubmit(event, this);
    });
  }, []);

  useEffect(() => {
    form.current.reset();
    setTimeout(() => {
      setMsgSendSuccess(false);
    }, 5000);
  }, [formSubmitted]);

  return (
    <React.Fragment>
      <section className="section " id="contact">
        <Container>
          <SectionTitle title1="Get In" title2="Touch" />
          <div className="custom-form mt-4 pt-4">
            <div id="message">
              {msgSendSuccess ? (
                <Animated
                  animationIn="bounceIn"
                  animationOut="flash"
                  animationInDuration={1000}
                  animationOutDuration={1000}
                  isVisible={true}
                >
                  <fieldset>
                    <div id="success_page">
                      <h3>Email Sent Successfully.</h3>
                      <p>
                        Thank you <strong>{formFields.firstname}</strong>,
                        your message has been submitted to us.
                      </p>
                    </div>
                  </fieldset>
                </Animated>
              ) : null}
            </div>
            <AvForm
              name="contact-form"
              id="contact-form"
              ref={form}
            >
              <Row>
                <Col md="4">
                  <FormGroup>
                    <Label for="name">Name</Label>
                    <AvField
                      name="firstname"
                      placeholder="Your name..."
                      type="text"
                      errorMessage="Enter First Name"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="validationCustom01"
                      onChange={(e) => onInputChangeHandler(e)}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label for="email">Email address</Label>
                    <AvField
                      name="email"
                      placeholder="Your email..."
                      type="email"
                      errorMessage="Enter Valid Email Adress"
                      className="form-control"
                      validate={{
                        required: { value: true },
                        email: { value: true },
                        pattern: { value: '^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$' },
                      }}
                      onChange={(e) => onInputChangeHandler(e)}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label for="subject">Subject</Label>
                    <AvField
                      name="subject"
                      placeholder="Your Subject.."
                      type="text"
                      errorMessage="Enter Subject Name"
                      className="form-control"
                      validate={{ required: { value: true } }}
                      id="validationCustom01"
                      onChange={(e) => onInputChangeHandler(e)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <Label for="comments">Message</Label>
                    <AvField
                      type="textarea"
                      name="comments"
                      id="comments"
                      rows="4"
                      className="form-control"
                      errorMessage="Enter your message."
                      placeholder="Your message..."
                      validate={{ required: { value: true } }}
                      onChange={(e) => onInputChangeHandler(e)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm="12" className="text-right">
                  <input
                    type="submit"
                    id="submit"
                    name="send"
                    className="submitBnt btn btn-primary btn-custom"
                    value={(loading) ? "Loading..." : "Send Message"}
                    disabled={(loading) ? true : false}
                  />
                  <div id="simple-msg"></div>
                </Col>
              </Row>
            </AvForm>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );

};

export default GetInTouch;
