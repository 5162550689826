import React, { Component } from 'react';
import { Container, Row } from "reactstrap";

//Import Components
import SectionTitle from "../../components/common/section-title";

//Import Team Box
import TeamBox from "./team-box";

//Import Images
import team1 from "../../assets/images/team/team-1.png";
import team2 from "../../assets/images/team/team-2.png";
import team3 from "../../assets/images/team/team-3.png";
import team4 from "../../assets/images/team/team-4.png";

class OurTeam extends Component {
    state = {
        members : [
            { id : 1, name : "JAN DIJKSTRA", image : team1, post : "Chief Value Officer & Co-Founder",
                socials : [

                    { class : "mdi mdi-linkedin", link : "https://www.linkedin.com/in/jan-dijkstra-547a232/" },
                ]
            },
            { id : 2, name : "GEORGE RICARDO", image : team2, post : "Project Manager/Co-Founder",
                socials : [
                    { class : "mdi mdi-linkedin", link : "https://www.linkedin.com/in/georgericardo/" },
                ]
            },
            { id : 3, name : "JOSÉ LUIS ZORRILLA", image : team3, post : "Chief Product Officer & Co-Founder",
                socials : [
                    { class : "mdi mdi-linkedin", link : "https://www.linkedin.com/in/joseluiszorrilla/" },
                ]
            },
            { id : 4, name : "YVES-MICHEL LEPORCHER", image : team4, post : "External Advisor",
                socials : [
                    { class : "mdi mdi-linkedin", link : "https://www.linkedin.com/in/yves-michel-leporcher-54143320/" },
                ]
            },
        ]
    }
    render() {
        return (
            <React.Fragment>
        <section className="greenivity-section-our-team" id="team">
            <Container>
                <SectionTitle title1="Our" title2="Team" />
                <Row className="mt-4 pt-4">
                    <TeamBox members={this.state.members} />
                </Row>
            </Container>
        </section>
            </React.Fragment>
        );
    }
}

export default OurTeam;