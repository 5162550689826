import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Components
import SectionTitle from "../../components/common/section-title";

//Import Images
import img1 from "../../assets/images/features/greenivity_ai.png";
import img2 from "../../assets/images/features/greenivity_blockchain.png";
import img3 from "../../assets/images/features/mockup2.png";
import img4 from "../../assets/images/features/mockup1.png";
import FeatureBox from "../common/feature-box";

class AdditionalFeatures extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="section additional-features" id="additional-features">
          <Container>
            <SectionTitle title1="Additional" title2="Features" />
            <Row className="mt-12 pt-12">
                <Col lg="3" className="mt-3">
                    <div class="basic-card basic-card-aqua">
                        <div class="card-content">
                            <span class="card-title">IoT integration</span>
                            <p class="card-text">
                                <br></br>
                                Elevate emissions recording with the precision of IoT: Real-time monitoring grants instant insights, strategic placement captures key data points, granular data unveils precise details, while automated reporting streamlines tasks, all leading to improved accuracy for informed decision-making.
                            </p>
                        </div>

                        <div class="card-link">
                        </div>
                    </div>
                </Col>
                <Col lg="3" className="mt-3">
                    <div class="basic-card basic-card-aqua">
                        <div class="card-content">
                            <span class="card-title">Carbon credit trading platform connection</span>
                            <p class="card-text">
                            <br></br>
                            By integrating with carbon credit exchange platforms, the Greenivity platform will enable automatic trading of carbon credits at preset prices, comparing opportunities across multiple exchanges to maximize your profits and simplify compliance.
                            </p>
                        </div>

                        <div class="card-link">
                        </div>
                    </div>
                </Col>
                <Col lg="3" className="mt-3">
                    <div class="basic-card basic-card-aqua">
                        <div class="card-content">
                            <span class="card-title">ERP Software Systems Integration</span>
                            <p class="card-text">
                            <br></br>
                            Improve the efficiency, consistency and accuracy of the information collected by linking with the most popular management software on the market such as SAP, Oracle and Microsoft Dynamics.
                            </p>
                        </div>

                        <div class="card-link">
                        </div>
                    </div>
                </Col>
                <Col lg="3" className="mt-3">
                    <div class="basic-card basic-card-aqua">
                        <div class="card-content">
                            <span class="card-title">Digital Twin features</span>
                            <ul>
                            <br></br>
                                <li>Ability for 3D consolidated overviews</li>
                                <li>VR</li>
                                <li>Vector based / Coordinate-based emission insight</li>
                                <li>Platform for digital W3 meetings</li>
                            </ul>
                        </div>

                        <div class="card-link">
                        </div>
                    </div>
                </Col>
            </Row>
            {/* <Row className="mt-4 pt-4 vertical-content">
              <Col lg="6" className="mt-2">
                <div className="features-image-left">
                  <img
                    src={img3}
                    alt=""
                    className="img-fluid mx-auto d-block"
                  />
                </div>
              </Col>
              <Col lg="6" className="mt-2 text-center">
                <FeatureBox
                  class=""
                  title="Reporting"
                  desc="Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                  link="#"
                />
              </Col>
            </Row> */}
            {/* <Row className="mt-5 vertical-content">
              <Col lg="6" className="mt-2">
                <FeatureBox
                  class="text-center"
                  title="Optimization"
                  desc="Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                  link="#"
                />
              </Col>
              <Col lg="6" className="mt-2">
                <div className="features-image-right">
                  <img
                    src={img4}
                    alt=""
                    className="img-fluid mx-auto d-block"
                  />
                </div>
              </Col>
            </Row> */}
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default AdditionalFeatures;
